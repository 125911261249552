@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-color {
  background-color: #98012e;
}

.font-color {
  color: #98012e;
}

.button-color {
  background-color: #832333;
}

.leaderboard {
  height: 600px;
}